import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBOtCMMqHbQc3Wa6G1l35yqj8ieKuASl80",
  authDomain: "smartsmartcloudfile.firebaseapp.com",
  projectId: "smartsmartcloudfile",
  storageBucket: "smartsmartcloudfile.appspot.com",
  messagingSenderId: "1034373079760",
  appId: "1:1034373079760:web:35aa501536d300177a34d5",
  measurementId: "G-244Q2V5NWW",
};

export const app = initializeApp(firebaseConfig);
